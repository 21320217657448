import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';

const Impressum = ({data}) => (
  <Layout>
    <Helmet>
      <title>Sebastian Klaus - Softwareentwicklung und Beratung | Impressum</title>
      <meta name="description" content="Sebastian Klaus - Softwareentwicklung und Beratung - Impressum" />
    </Helmet>
    <section className="pt-5 md:pt-20 shadow-lg bg-static-sites text-white px-5">
      <div className="container mx-auto pb-3">
        <h1>Impressum</h1>
        <Link className="text-white hover:text-white hover:underline" to="/">zurück zur Startseite</Link>
      </div>
    </section>
    <section className="pt-5 px-5">
      <div className="container mx-auto" dangerouslySetInnerHTML={{__html: data.file.childHtmlRehype.html}} />
    </section>
  </Layout>
);

// export page query
export const query = graphql`
  query ImprintContent {
    file(name: {eq: "impressum"}) {
      childHtmlRehype {
        html
      }
    }
  }
`

export default Impressum;
